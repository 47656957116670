import tw from "twin.macro"
export const Button = tw.button`uppercase bg-primary leading-6 text-white text-lg transition font-medium rounded-md px-6 py-1 pt-2 w-full lg:(w-auto) border hover:(bg-white border-primary border text-primary)`
export const styles = {
  inputGroup: [tw`mb-2`],
  input: [
    tw`px-4 py-2 rounded-lg border-2 mb-1 placeholder-gray-300 text-white bg-transparent w-full border-white focus:(border-white outline-none) focus-visible:(ring ring-tertiary ring-2)`,
    tw`checked:(text-white)`,
    // collection !== "get-sponsored-data" &&
    //   tw`border-primary  placeholder-gray-400 text-primary`,
  ],
  select: [
    `background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e")`,
    //   collection !== "get-sponsored-data" &&
    //     `background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%23FF5C5D' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e")`,
  ],
  label: [
    tw`text-white text-sm mb-1 uppercase`,
    // collection !== "get-sponsored-data" && tw`text-primary`,
  ],
  button: [
    tw`uppercase bg-primary leading-6 text-white text-lg transition rounded-md px-6 py-1 pt-2 w-full font-medium lg:(w-auto) border hover:(bg-white border-primary border text-primary)`,
    tw`bg-white text-primary transition hover:(bg-primary text-white)`,
    // collection !== "get-sponsored-data" &&
    //   tw`text-white bg-primary hover:(text-primary bg-transparent border-primary)`,
  ],
  error: [
    tw`text-white text-sm h-5`,
    // collection !== "get-sponsored-data" && tw`text-primary`,
  ],
}
