import React, { useState } from "react"
import { useForm } from "react-hook-form"
import tw from "twin.macro"
import firebase from "gatsby-plugin-firebase"
import uniqid from "uniqid"

const NewForm = ({ setState, collection, shirts, gradient }) => {
  const provinces = [
    "Ontario",
    "Quebec",
    "Nova Scotia",
    "New Brunswick",
    "Manitoba",
    "British Columbia",
    "Prince Edward Island",
    "Saskatchewan",
    "Alberta",
    "Newfoundland and Labrador",
    "Northwest Territories",
    "Nunavut",
    "Yukon",
  ]

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm()

  const watchProvince = watch("province")
  const watchTeam = watch("team")
  const watchFile = watch("fileinput")

  const [progress, setProgress] = useState(0)

  const onSubmit = data => {
    let uniqueid = uniqid()

    if (data.fileinput) {
      data.fileinput = uniqueid
    }

    firebase
      .firestore()
      .collection(collection)
      .add({ data })
      .then(ref => {
        console.log("Added document with ID: ", ref.id)
        if (data.fileinput) {
          uploadFile(uniqueid)
        } else {
          setState(true)
        }
        // sendEmail(data)
      })
  }

  const chooseFile = e => {
    e.preventDefault()
    document.getElementById("fileinput").click()
  }

  const uploadFile = uniqueid => {
    const file = document.getElementById("fileinput").files[0]
    const storageRef = firebase.storage().ref()
    const uploadTask = storageRef.child(`${collection}/${uniqueid}`).put(file)

    uploadTask.on(
      "state_changed",
      snapshot => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        setProgress(progress)
      },
      error => {
        console.log(error)
      },
      () => {
        console.log("Upload complete")
        setState(true)
      }
    )
  }

  const InputGroup = tw.div`mb-2`
  const styles = {
    inputGroup: [tw`mb-2`],
    input: [
      tw`px-4 py-2 rounded-lg border-2 mb-1 placeholder-gray-300 text-white bg-transparent w-full border-white focus:(border-white outline-none) focus-visible:(ring ring-tertiary ring-2)`,
      tw`checked:(text-white)`,
      gradient && tw`border-primary  placeholder-gray-400 text-primary`,
    ],
    select: [
      `background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e")`,
      gradient &&
        `background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%23FF5C5D' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e")`,
    ],
    label: [
      tw`text-white text-sm mb-1 uppercase`,
      gradient && tw`text-primary`,
    ],
    button: [
      tw`uppercase bg-primary leading-6 text-white text-lg transition rounded-md px-6 py-1 pt-2 w-full font-medium lg:(w-auto)  hover:(bg-white border-primary border text-primary)`,
      tw`bg-white text-primary transition hover:(bg-primary text-white)`,
      gradient && tw`text-white bg-primary hover:(text-primary bg-transparent)`,
    ],
    error: [tw`text-white text-sm h-5`, gradient && tw`text-primary`],
  }

  const sendEmail = async (data, e) => {
    try {
      const response = await fetch("/api/form-submit", {
        method: "POST",
        body: JSON.stringify(data),
      })

      if (!response.ok) {
        //not 200 response
        return
      }
      //all OK
      // customEvent(`${getCurrentDate()}`, 'Send Email', `${userEmail} Send Email Success`);
    } catch (e) {
      // customEvent(`${getCurrentDate()}`, 'Send Email', `${userEmail} Send Email Fail`);
      //error
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div css={styles.inputGroup}>
        <label css={styles.label} htmlFor="name">
          Your Name
        </label>
        <input
          id="name"
          css={styles.input}
          name="name"
          type="text"
          placeholder="John Smith"
          {...register("name", {
            required: "* Please enter your name.",
          })}
        />
        <p css={styles.error}>{errors.name?.message}</p>
      </div>

      <div css={styles.inputGroup}>
        <label css={styles.label} htmlFor="email">
          email
        </label>
        <input
          id="email"
          css={styles.input}
          name="email"
          type="email"
          placeholder="name@domain.com"
          {...register("email", {
            required: "* Please enter your email.",
          })}
        />
        <p css={styles.error}>{errors.email?.message}</p>
      </div>

      {collection.includes("submissions-closed-data") ? (
        <InputGroup>
          <label css={styles.label} htmlFor="aboutYourTeam">
            Tell us about your team
          </label>
          <textarea
            css={styles.input}
            id="aboutYourTeam"
            name="aboutYourTeam"
            type="text"
            placeholder="Share something about your team."
            {...register("aboutYourTeam", {
              required: "* Please tell us about your team.",
            })}
          />
          <p css={styles.error}>{errors.aboutYourTeam?.message}</p>
        </InputGroup>
      ) : (
        <>
          <InputGroup>
            <label css={styles.label} htmlFor="city">
              City
            </label>
            <input
              css={styles.input}
              id="city"
              type="city"
              placeholder="Toronto"
              {...register("city", { required: "* Please enter your city." })}
            />
            <p css={styles.error}>{errors.city?.message}</p>
          </InputGroup>

          <InputGroup>
            <label css={styles.label} htmlFor="province">
              Province
            </label>
            <select
              css={[
                styles.input,
                styles.select,
                !watchProvince &&
                  (collection !== "get-sponsored-data"
                    ? tw`text-gray-400`
                    : tw`text-gray-300`),
              ]}
              id="province"
              type="province"
              defaultValue=""
              {...register("province", {
                required: "* Please enter your Province.",
              })}
            >
              <option value="" disabled>
                Select an option
              </option>
              {provinces.map((item, idx) => {
                return (
                  <option key={idx} value={item}>
                    {item}
                  </option>
                )
              })}
            </select>
            <p css={styles.error}>{errors.province?.message}</p>
          </InputGroup>

          <InputGroup>
            <label css={styles.label} htmlFor="sport">
              What sport does your team play?
            </label>
            <input
              css={styles.input}
              id="sport"
              name="sport"
              type="text"
              placeholder="Curling"
              {...register("sport", { required: "* Please enter your sport." })}
            />
            <p css={styles.error}>{errors.sport?.message}</p>
          </InputGroup>

          {shirts && (
            <>
              <InputGroup>
                <label css={styles.label} htmlFor="team">
                  Which team would you like to play for? No promises!
                </label>
                <select
                  css={[
                    styles.input,
                    styles.select,
                    !watchTeam && tw`text-gray-300`,
                  ]}
                  id="team"
                  name="team"
                  defaultValue=""
                  {...register("team", {
                    required: "* Please enter your team.",
                  })}
                >
                  <option value="" disabled>
                    Select an option
                  </option>
                  {shirts.map((item, idx) => {
                    return (
                      <option key={idx} value={item.title}>
                        {item.title}
                      </option>
                    )
                  })}
                </select>
                <p css={styles.error}>{errors.team?.message}</p>
              </InputGroup>
              <InputGroup>
                <label css={styles.label} htmlFor="whyTeamRad">
                  Why does your team need a refresh?
                </label>
                <textarea
                  css={styles.input}
                  id="whyRefresh"
                  name="whyRefresh"
                  type="text"
                  placeholder="Tell us."
                  {...register("whyRefresh", {
                    required: "* Please tell us why your team needs a refresh.",
                  })}
                />
                <p css={styles.error}>{errors.whyRefresh?.message}</p>
              </InputGroup>
            </>
          )}

          {collection.includes("existing-team-data") && (
            <>
              <InputGroup>
                <label css={styles.label} htmlFor="teamName">
                  Team Name
                </label>
                <input
                  css={styles.input}
                  id="teamName"
                  name="teamName"
                  type="text"
                  placeholder="A sports pun"
                  {...register("teamName", {
                    required: "* Please enter your Team Name.",
                  })}
                />
                <p css={styles.error}>{errors.teamName?.message}</p>
              </InputGroup>
              <InputGroup>
                <label css={styles.label} htmlFor="whyTeamRad">
                  Why is your team a rad team?
                </label>
                <textarea
                  css={styles.input}
                  id="whyTeamRad"
                  name="whyTeamRad"
                  type="text"
                  placeholder="Tell us you're rad."
                  {...register("whyTeamRad", {
                    required: "* Please tell us why your team is rad.",
                  })}
                />
                <p css={styles.error}>{errors.whyTeamRad?.message}</p>
              </InputGroup>

              <div css={styles.inputGroup}>
                <div tw="flex justify-between">
                  <div>
                    <p tw="uppercase">Upload team logo</p>
                    {watchFile && watchFile[0] && (
                      <p tw="text-sm">{watchFile[0].name}</p>
                    )}
                  </div>

                  <button
                    onClick={e => chooseFile(e)}
                    css={[styles.button, tw`w-[46%] lg:(w-auto)`]}
                  >
                    Choose File
                  </button>
                </div>

                <input
                  id="fileinput"
                  name="fileinput"
                  type="file"
                  {...register("fileinput", {
                    required: "* Please upload your team's logo.",
                  })}
                  accept="image/png, image/jpeg"
                  hidden
                />
                <p css={styles.error}>{errors.fileinput?.message}</p>
              </div>
            </>
          )}
        </>
      )}

      <div css={styles.inputGroup}>
        <div tw="mb-2">
          <input
            tw="w-6 h-6 border-2 border-white bg-transparent rounded text-secondary hover:(border-white) focus:outline-none focus:(ring-2 ring-tertiary) checked:(border-white bg-transparent)"
            type="checkbox"
            id="age"
            name="age"
            {...register("age", { required: "Please confirm your age." })}
          />
          <label css={[styles.label, tw`ml-2`]} htmlFor="age">
            I'm 19 or older
          </label>
        </div>
        <p css={styles.error}>{errors.age?.message}</p>
      </div>

      <div tw="flex items-center mt-8">
        <button css={[styles.button]} type="submit">
          Submit
        </button>
        {collection.includes("existing-team-data") && progress > 0 && (
          <div tw="text-white ml-3 text-lg">
            Uploading... {Math.round(progress)}
          </div>
        )}
      </div>
    </form>
  )
}

export default NewForm
