import React, { useState, useEffect } from "react"
import tw from "twin.macro"
import NewForm from "./new-form"
import { StaticImage } from "gatsby-plugin-image"

const SubmissionsClosed = ({}) => {
  const [formSent, setFormSent] = useState(false)

  useEffect(() => {
    if (formSent) {
      document.getElementById("one-step-closer").scrollIntoView()
    }
  }, [formSent])

  return (
    <div tw="mb-6 lg:(flex justify-end pr-12) xl:(pr-20)">
      <div
        id="one-step-closer"
        css={[`scroll-margin-top: 20px;`, tw`relative z-10`]}
      >
        {!formSent ? (
          <>
            <h2 tw="uppercase text-6xl mx-auto text-center text-tertiary font-bold mb-4 lg:(text-left text-8xl leading-[.90])">
              <span tw="text-white block">SUBMISSIONS</span> Closed
              <StaticImage
                placeholder="NONE"
                src="../images/all-cans.png"
                tw="w-[6.5rem]   lg:(-ml-1 w-[10rem])"
                alt=""
              />
            </h2>
            <p tw="text-left text-lg mb-4 text-white ">
              We are no longer accepting submissions for Team Refresh 2022. If
              you would like updates on season 2023 let us knwo below and be
              sure to follow{" "}
              <a
                tw="border-b"
                target="_blank"
                referrer="none"
                href="https://instagram.com/mooseheadradler"
              >
                @mooseheadradler
              </a>
              !
            </p>

            <NewForm
              state={formSent}
              setState={setFormSent}
              collection={process.env.GATSBY_SUBMISSIONS_CLOSED_DB}
            />
          </>
        ) : (
          <div
            css={[
              tw`text-white transition-all h-[600px]`,
              formSent && tw`visible`,
            ]}
          >
            <h2 tw=" text-6xl font-bold mb-8 uppercase lg:(text-8xl  leading-[.90] mb-2)">
              THANKS FOR
              <br />
              YOUR
              <StaticImage
                placeholder="NONE"
                src="../images/all-cans.png"
                tw="w-[6.5rem]   lg:(-ml-1 w-[10rem])"
                alt=""
              />
              <span tw="text-tertiary">Interest</span>
            </h2>
            <p tw="text-lg mb-12">
              We’ll be in touch in the future with updates for the 2023 season.
              Stay fresh!
            </p>
            <StaticImage
              tw="w-52 mx-auto block"
              src="../images/moosehead-lockup.png"
              alt="Moosehead"
              placeholder="NONE"
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default SubmissionsClosed
