import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import tw from "twin.macro"
import {
  StaticImage,
  GatsbyImage,
  getImage,
  withArtDirection,
} from "gatsby-plugin-image"
import { Button } from "../styles/MooseheadRadlerUI"

import Header from "../components/header"

import InstagramIcon from "../images/instagram-icon.svg"
import MailIcon from "../images/mail-icon.svg"

import { isBrowser } from "../utils"

import slugify from "slugify"

import SubmissionsClosed from "../components/SubmissionsClosed"

const IndexPage = ({ location, data }) => {
  const [selectedIdx, setSelectedIdx] = useState(false)
  const [expandRow, setExpandRow] = useState(false)
  const [row, setRow] = useState(0)
  const [selected, setSelected] = useState(false)

  const [copiedCopy, setCopiedCopy] = useState("")
  const shirts = [
    {
      image: data.rdImage,
      title: "Rad Dads",
      copy: "You don’t have to be a dad to be a Rad Dad. You just need a juicy zest for life, a love for denim and a set of BBQ tongs to click together before totally roasting your opponents.",
    },
    {
      image: data.ttImage,
      title: "Thirst Trappers",
      copy: "So much drip. So fresh. Every game is one giant thirst trap. In fact, it’s only safe to wear these Thirst Trappers jerseys if you’re fully hydrated.",
    },
    {
      image: data.scImage,
      title: "Slugger Chuggers",
      copy: "You could be The Slugger Chuggers if you like taking big swings and big swigs of post-game refreshment. Claim this team before it’s going, going gone!",
    },
    {
      image: data.rrImage,
      title: "Rads to Riches",
      copy: "Started from the bleachers now we’re here. Choose Rads to Riches if you’re ready to cash in all over the field, diamond or court.",
    },
    {
      image: data.rpImage,
      title: "Rad Pack",
      copy: "You could be the Rad Pack if your team packs a punch. You’ll dine on your opponents breakfast, dinner and lunch. Oops. We didn’t mean for this to be a rhyme. But, if you’re looking for a team this one’s right on time.",
    },
    {
      image: data.rsImage,
      title: "Radler Snakes",
      copy: "This team is all you if you embody the Radler Snake spirit. Always lurking in the grass. Ready to strike at any moment. ",
    },
    {
      image: data.gwImage,
      title: "Glow Worms",
      copy: "Get ready to absolutely light up the field. This totally radlercal throwback squad will always shine day or night.",
    },
    {
      image: data.bqImage,
      title: "Bench Quenchers",
      copy: "Act fast. These Bench Quenchers jerseys won’t be around long. Why? Because raccoons.",
    },
  ]
  const images = withArtDirection(getImage(data.tmImageLarge), [
    {
      media: "(max-width: 1024px)",
      image: getImage(data.tmImageSmall),
    },
  ])

  const callExpand = idx => {
    let timeout = expandRow ? 350 : 0
    let cols = window.innerWidth > 768 ? 4 : 2

    //set param
    // setShirt(`a-test${idx}`)
    setExpandRow(false)
    setCopiedCopy("")

    setTimeout(() => {
      setSelectedIdx(idx)
      setSelected(shirts[idx])
      setRow(Math.ceil((idx + 1) / cols) + 1)
      setTimeout(() => {
        setExpandRow(true)
        const element = document.getElementById(`jersey-${idx}`)
        element &&
          element.scrollIntoView({
            behavior: "smooth",
            // block: "end",
            inline: "nearest",
          })
      }, 50)
    }, timeout)
  }

  const copyToClipboard = () => {
    const url = location.href
    navigator.clipboard.writeText(url)
    setCopiedCopy("Copied to clipboard")
  }

  //get hash
  useEffect(() => {
    if (!isBrowser) {
      return
    }

    const hash = location.hash.split("#")[1]

    var result = shirts.filter(obj => {
      return slugify(obj.title, { lower: true }) === hash
    })

    const index = shirts.indexOf(result[0])

    callExpand(index)
  }, [])

  return (
    <Layout>
      <Seo title="Home" />

      <Header />

      <section tw="max-w-7xl mx-auto py-14 px-4">
        <h2 tw="text-center text-6xl px-6 lg:(px-0 text-8xl) text-primary uppercase font-bold mb-4">
          Play for 1 of&nbsp;8&nbsp;teams
        </h2>
        <div tw="grid grid-cols-2 gap-x-8 lg:(grid-cols-4 gap-x-20)">
          {shirts.map((item, idx) => {
            return (
              <a
                href={`#${slugify(item.title, { lower: true })}`}
                className="group"
                tw="text-center inline-block py-4 lg:(py-12)"
                key={idx}
                onClick={() => callExpand(idx)}
                id={`jersey-${idx}`}
              >
                <div tw="lg:(px-6)">
                  <GatsbyImage
                    image={getImage(item.image)}
                    alt={item.title}
                    tw="transform transition mb-4 group-hover:(-translate-y-2)"
                  />
                </div>
                <h3
                  css={[
                    tw`text-center text-xl uppercase inline-block text-primary font-bold transition border-b-2 border-white`,
                    idx === selectedIdx && tw`border-primary`,
                  ]}
                >
                  {item.title}
                </h3>
              </a>
            )
          })}
          {/* pop up */}
          {selected && (
            <div
              id="jersey-details"
              css={[
                `grid-row-start: ${row}`,
                tw`col-span-2 transition transition-all items-center max-h-0 overflow-hidden duration-300 lg:(col-span-4)`,
                tw`border-b-2 border-t-2 border-primary opacity-0`,
                tw`last:(border-b-0)`,
                expandRow && tw` max-h-[600px] opacity-100`,
              ]}
            >
              <div tw="flex py-8 items-center max-w-4xl mx-auto lg:(py-12)">
                <div tw="hidden lg:(block) mr-8 w-2/5">
                  <GatsbyImage
                    alt={selected.title}
                    image={getImage(selected.image)}
                  />
                </div>
                <div tw="text-primary lg:(w-3/5)">
                  <h3 tw="text-2xl font-bold uppercase mb-4 lg:(text-3xl)">
                    {selected.title}
                  </h3>
                  <p tw="text-lg mb-6 leading-tight">{selected.copy}</p>

                  <Button tw="mb-2" onClick={copyToClipboard}>
                    Share with your team
                  </Button>
                  <p tw="h-4">{copiedCopy}</p>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>

      <section tw="bg-gradient-to-b from-primary to-secondary py-14 overflow-hidden lg:(py-24)">
        <div tw="max-w-7xl mx-auto px-4 relative z-10 grid lg:(gap-12 grid-cols-5) xl:(grid-cols-2)">
          <div tw="lg:(col-start-1 col-span-3) xl:(col-span-1)">
            <SubmissionsClosed />
          </div>

          <div tw="-mx-4 flex justify-center row-start-3 lg:(mx-0 mt-[5.5rem] row-start-1 col-start-4 col-span-2) xl:(col-start-2 col-span-1)">
            <div>
              <GatsbyImage
                // tw="h-[600px] w-auto lg:(h-auto)"t
                tw="aspect-ratio[1/1]  lg:(aspect-ratio[auto])"
                alt="Team photo"
                image={images}
              />
            </div>
          </div>
        </div>

        <footer tw="max-w-7xl mx-auto px-4 py-14 pb-0 text-white relative">
          <StaticImage
            tw="opacity-20 absolute bottom-[600px] hidden left-0 w-96 lg:(block bottom-48)"
            src="../images/grapefruit.png"
            alt="grapefruit illustration"
            placeholder="None"
          />
          <div tw="grid lg:(grid-cols-8 gap-12)">
            <div tw="col-span-2 mb-8">
              <a
                href="https://instagram.com/mooseheadradler"
                target="_blank"
                tw="flex items-center lg:(grid grid-cols-[30px])"
                rel="noreferrer"
              >
                <img
                  alt="Instagram Icon"
                  src={InstagramIcon}
                  tw="mr-4 lg:(mr-2)"
                />
                <h3 tw="text-lg lg:(block row-start-1 col-span-2 mb-2)">
                  Follow Moosehead Radler
                </h3>
                <span tw="hidden lg:(block)">Instagram</span>
              </a>
            </div>
            <div tw="col-span-2 mb-8">
              <a
                href="mailto:info@moosehead.ca"
                target="_blank"
                tw="flex items-center lg:(grid grid-cols-[30px])"
                rel="noreferrer"
              >
                <img alt="Mail Icon" src={MailIcon} tw="mr-4 lg:(mr-2)" />
                <h3 tw="text-lg lg:(block row-start-1 col-span-2 mb-2)">
                  Contact Us
                </h3>
                <span tw="hidden lg:(block)">info@moosehead.ca</span>
              </a>
            </div>
          </div>
          <div tw="grid grid-cols-1 md:(grid-cols-2)">
            <div tw="mb-8 md:(mb-0)">
              <a
                tw="mr-8"
                href="https://moosehead.ca/privacy"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
              <a
                href="https://moosehead.ca/terms"
                target="_blank"
                rel="noreferrer"
              >
                Terms of Use
              </a>
            </div>

            <div tw="lg:text-right">© Moosehead Radler Team Refresh 2022.</div>
          </div>
        </footer>
      </section>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query HomePageQuery {
    smallImage: file(relativePath: { eq: "lock-up-sm.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 640
          formats: [AUTO, WEBP, AVIF]
          placeholder: BLURRED
        )
      }
    }
    largeImage: file(relativePath: { eq: "lock-up-lg.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 498
          placeholder: NONE
          quality: 100
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    bqImage: file(relativePath: { eq: "jerseys/bench-quenchers.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 498
          placeholder: NONE
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    gwImage: file(relativePath: { eq: "jerseys/glow-worms.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 498
          placeholder: NONE
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    rdImage: file(relativePath: { eq: "jerseys/rad-dad.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 498
          placeholder: NONE
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    rpImage: file(relativePath: { eq: "jerseys/rad-pack.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 498
          placeholder: NONE
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    rsImage: file(relativePath: { eq: "jerseys/radler-snakes.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 498
          placeholder: NONE
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    rrImage: file(relativePath: { eq: "jerseys/rads-to-riches.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 498
          placeholder: NONE
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    scImage: file(relativePath: { eq: "jerseys/sluggers-chuggers.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 498
          placeholder: NONE
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    ttImage: file(relativePath: { eq: "jerseys/thirst-trappers.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 498
          placeholder: NONE
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    ytImage: file(relativePath: { eq: "jerseys/your-team-here.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 400
          formats: [AUTO, WEBP, AVIF]
          placeholder: NONE
        )
      }
    }
    tmImageLarge: file(relativePath: { eq: "team-lg.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 600
          placeholder: NONE
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    tmImageSmall: file(relativePath: { eq: "team-sm.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, formats: [AUTO, WEBP, AVIF])
      }
    }
  }
`
