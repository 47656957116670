import React from "react"
import "twin.macro"
import carouselGif from "../images/jersey-carousel.gif"
import { StaticImage } from "gatsby-plugin-image"

const Header = ({ images }) => {
  return (
    <section tw="bg-gradient-to-b from-secondary to-primary text-white py-12 lg:(py-24)">
      <header tw="max-w-7xl mx-auto px-4">
        <div tw="grid grid-cols-1  lg:(grid-cols-7  gap-y-0 items-center) ">
          <div tw="flex justify-center px-4 mb-4 lg:(justify-start px-0 items-end col-span-3)">
            <h1 tw="text-center uppercase font-bold text-6xl leading-[3.3rem] lg:(text-8xl text-left leading-[4.8rem] )">
              <span tw="text-tertiary">
                Refresh
                <br /> your team
              </span>
              <br />
              <StaticImage
                placeholder="NONE"
                src="../images/with-script.png"
                alt="with"
                tw="w-28 -mt-2 -ml-2 lg:(w-36)"
              />
              <br />
              Moosehead
              <br />
              <StaticImage
                placeholder="NONE"
                src="../images/left-cans.png"
                tw="w-12 -mt-1 mr-1 inline-block lg:hidden"
                alt=""
              />
              Radler
              <StaticImage
                placeholder="NONE"
                src="../images/right-cans.png"
                tw="w-12 -mt-1 ml-1 inline-block lg:hidden"
                alt=""
              />
              <StaticImage
                placeholder="NONE"
                src="../images/all-cans.png"
                tw="w-[31%] -mt-2 -ml-1 hidden lg:(inline-block)"
                alt=""
              />
            </h1>
          </div>
          <div tw="flex justify-center px-12 mb-8 lg:(px-0 mb-0 col-span-4 col-start-4 row-span-2 justify-end ) xl:(col-start-5 col-span-3 justify-center)">
            <img src={carouselGif} alt="t shirts" tw="lg:(w-3/4) xl:(w-4/5)" />
          </div>
          <div tw="text-lg lg:(col-span-3)">
            <p tw="mb-2">
              This summer, we’re sponsoring sports teams like yours. As an
              official Moosehead Radler team, you’ll get a fresh new name, fresh
              new jerseys, Moosehead Radler team swag, free post-game
              refreshment and even a $1,000 league fee credit to your next
              season of play.{" "}
              <span tw="text-tertiary">
                Softball? Soccer? Ultimate Frisbee? Dodgeball?
              </span>{" "}
              Whatever summer sport you play, let’s make it the freshest summer
              ever.
            </p>
          </div>
        </div>
      </header>
    </section>
  )
}

export default Header
